import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import ForgotPassword from './pages/ForgotPassword';
import NewPassword from './pages/NewPassword';
import PasswordToken from './pages/PasswordToken';
import RegisterPage from './pages/RegisterPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import LessonPlan from './pages/LessonPlan';
import AllTools from './pages/AllTools';
import Assessment from './pages/Assessment';
import Handout from './pages/Handout';
import GrammarCorrection from './pages/GrammarCorrection';
import MathSolver from './pages/MathSolver';
import TexttoImage from './pages/TexttoImage';
import Curriculum from './pages/Curriculum';
import ActivityGenerator from './pages/ActivityGenerator';
import PDFDownload from './pages/PDFDownload';
import Checkout from './pages/Checkout';
import Checkoutf from './pages/Checkoutf';
import PersistLogin from './pages/PersistLogin';
import PrintPDF from './pages/PrintPDF';
import Logout from './pages/Logout';
import Teams from './pages/Teams';
import Users from './pages/Users';
import AudioToText from './pages/AudioToText';
import AccountSettings from './pages/AccountSettings';
import MyAccount from './pages/MyAccount';
import Feedback from './pages/Feedback';
import AssignmentGenerator from './pages/AssignmentGenerator';
import MarkingschemeGenerator from './pages/MarkingschemeGenerator';
import AppraisalGenerator from './pages/AppraisalGenerator';
import ModuleGenerator from './pages/ModuleGenerator';
import IcebreakerActivity from './pages/IcebreakerActivity';
import BehaviorInterGenerator from './pages/BehaviorInterGenerator';
import VocabularyGenerator from './pages/VocabularyGenerator';
import NewsletterGenerator from './pages/NewsletterGenerator';
import SpecialNeedsGenerator from './pages/SpecialNeedsGenerator';
import LanguageTranslator from './pages/LanguageTranslator';
import PracticeTestGenerator from './pages/PracticeTestGenerator';
import WorksheetGenerator from './pages/WorksheetGenerator';
import ComprehensionGenerator from './pages/ComprehensionGenerator';
import QuizGenerator from './pages/QuizGenerator';
import WritingAssistant from './pages/WritingAssistant';
import TexttoSpeechGenerator from './pages/TexttoSpeechGenerator';

import ReportCardComment from './pages/ReportCardComment';
import RhymesGenerator from './pages/RhymesGenerator';
import TeachersJokesGenerator from './pages/TeachersJokesGenerator'
import TeacherRiddlesGenerator from './pages/TeacherRiddlesGenerator'
import ProjectBasedLearning from './pages/ProjectBasedLearning'
import GroupworkGenerator from './pages/GroupworkGenerator'
import ScienceLabProjectGenerator from './pages/ScienceLabProjectGenerator'
import SocialEmotionalLearning from './pages/SocialEmotionalLearning'
import CoachSportPracticeGenerator from './pages/CoachSportPracticeGenerator'
import SdgGenerator from './pages/SdgGenerator'
import PowerpointSlideGenerator from './pages/PowerpointSlideGenerator'
import VideoAudioAssessment from './pages/VideoAudioAssessment'

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      element: <PersistLogin />,
      children: [
        {
          path: '/dashboard',
          element: <DashboardLayout />,
          children: [
            { element: <Navigate to="/dashboard/app" />, index: true },
            { path: 'app', element: <DashboardAppPage /> },
            { path: 'user', element: <UserPage /> },
            { path: 'products', element: <ProductsPage /> },
            { path: 'blog', element: <BlogPage /> },
            { path: 'lessonplan', element: <LessonPlan /> },
            { path: 'alltools', element: <AllTools /> },
            { path: 'assessment', element: <Assessment /> },
            { path: 'handout', element: <Handout /> },
            { path: 'grammarcorrection', element: <GrammarCorrection /> },
            { path: 'solver', element: <MathSolver /> },
            { path: 'texttoimage', element: <TexttoImage /> },
            { path: 'curriculum', element: <Curriculum /> },
            { path: 'activitygenerator', element: <ActivityGenerator /> },
            { path: 'transcribe', element: <AudioToText /> },
            { path: 'downloadPDF', element: <PDFDownload /> },
            { path: 'checkout', element: <Checkoutf /> },
            { path: 'print-pdf', element: <PrintPDF /> },
            { path: 'team', element: <Teams /> },
            { path: 'users', element: <Users /> },
            { path: 'account', element: <AccountSettings /> },
            { path: 'user-account', element: <MyAccount /> },
            { path: 'feedback', element: <Feedback /> },
            { path: 'assignmentgenerator', element: <AssignmentGenerator /> },
            { path: 'markingscheme', element: <MarkingschemeGenerator /> },
            { path: 'appraisalgenerator', element: <AppraisalGenerator /> },
            { path: 'moduleplanner', element: <ModuleGenerator /> },
            { path: 'icebreakeractivity', element: <IcebreakerActivity /> },
            { path: 'behaviorintervention', element: <BehaviorInterGenerator /> },
            { path: 'vocabularygenerator', element: <VocabularyGenerator /> },
            { path: 'newslettergenerator', element: <NewsletterGenerator /> },
            { path: 'specialneedsgenerator', element: <SpecialNeedsGenerator /> },
            { path: 'languagetranslator', element: <LanguageTranslator /> },
            { path: 'practicetestgenerator', element: <PracticeTestGenerator /> },
            { path: 'worksheetgenerator', element: <WorksheetGenerator /> },
            { path: 'comprehensiongenerator', element: <ComprehensionGenerator /> },
            { path: 'quizgenerator', element: <QuizGenerator /> },
            { path: 'writingassistant', element: <WritingAssistant /> },
            { path: 'texttospeech', element: <TexttoSpeechGenerator /> },
            { path: 'reportcardcomment', element: <ReportCardComment /> },
            { path: 'rhymes', element: <RhymesGenerator /> },
            { path: 'teachersjokes', element: <TeachersJokesGenerator /> },
            { path: 'teacherriddles', element: <TeacherRiddlesGenerator /> },
            { path: 'projectbasedlearning', element: <ProjectBasedLearning /> },
            { path: 'groupwork', element: <GroupworkGenerator /> },
            { path: 'sciencelabproject', element: <ScienceLabProjectGenerator /> },
            { path: 'socialemotionallearning', element: <SocialEmotionalLearning /> },
            { path: 'coachsportpractice', element: <CoachSportPracticeGenerator /> },
            { path: 'sdggenerator', element: <SdgGenerator /> },
            { path: 'videoaudioassessment', element: <VideoAudioAssessment /> },
            { path: 'powerpointslide', element: <PowerpointSlideGenerator /> },
          ],
        }
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      path: 'logout',
      element: <Logout />,
    },
    { 
      path: 'forgotpassword', 
      element: <ForgotPassword /> 
    },
    { 
      path: 'newpassword', 
      element: <NewPassword /> 
    },
    { 
      path: 'verifytoken', 
      element: <PasswordToken /> 
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
