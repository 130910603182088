import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, TextField, InputLabel,MenuItem,FormControl,Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../components/iconify';
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth';
import curriculumList from '../../components/dropdowns/Curriculum'
import gradeList from '../../components/dropdowns/Grade'
import countryList from '../../components/dropdowns/CountryList'
import useLoader from '../../hooks/useLoader';

// ----------------------------------------------------------------------

export default function CurriculumForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { setLoading } = useLoader();

  const [subject, setSubject] = useState('');
  const [grade, setGrade] = useState('');
  const [country, setCountry] = useState('');
  const [objective, setObjective] = useState('');
  const serviceId = "curriculum generator";

  const handleSubmit = () => {
    setLoading(true)
    axiosPrivate.post(`/ai/teacher`, { 
      user_id : auth?.userId,
      serviceId,
      subject,
      grade,
      country,
      objective,

      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
        const result = res.data
        props.onSubmit({result, subject, grade, country})
        setLoading(false)
    })
    .catch(error => {
      console.log(error);
      setLoading(false)
      navigate('/login', {state: {from: location}, replace: true})
    });
  };


  const checkAccount = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
        const user_id = auth?.userId;
        const response = await axiosPrivate.get(`/ai/check/${user_id}/${serviceId}`);
        
        await handleSubmit()
    } catch (error) {
        setLoading(false)
        props.onSubmit({result: error.response.data, subject, grade, country})
        if(error.response.data == "Forbidden"){
          navigate('/login', {state: {from: location}, replace: true})
        }
    }
  };


  return (
    <>
      <form onSubmit={checkAccount}>
        <Stack spacing={3}>
          <TextField name="subject" label="Subject" onChange={e => setSubject(e.target.value)} />
          <FormControl fullWidth>
            <InputLabel id="grade-label">Grade</InputLabel>
            <Select labelId="grade-label" value={grade} label="Grade Level" onChange={e => setGrade(e.target.value)}>
              {gradeList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="curriculum-label">Curriculum Type (e.g. Kenya)</InputLabel>
            <Select labelId="curriculum-label" value={country} label="Curriculum Type" onChange={e => setCountry(e.target.value)}>
              {countryList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <TextField name="curriculum" label="Curriculum Type (e.g. UK Curriculum)" multiline="false" onChange={e => setCurriculum(e.target.value)} /> */}
          <TextField name="objective" label="Description (eg. First term) " multiline="true" onChange={e => setObjective(e.target.value)} />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 5 }}>
          Generate
        </LoadingButton>
      </form>
    </>
  );
}
